import { LazyMotion } from 'framer-motion';
import dynamic from 'next/dynamic';

import { CartProvider } from '../cart-context/cart-context';
import { FavoritesProvider } from '../favorites-context/favorites-context';
import {
  GlobalProvider,
  GlobalProviderProps,
} from '../global-context/global-context';

const MarketPickerProvider = dynamic(() =>
  import('@hultafors/shared/context').then(
    (module) => module.MarketPickerProvider,
  ),
);

const SWRProvider = dynamic(() =>
  import('@hultafors/shared/context').then((module) => module.SWRProvider),
);

const lazyMotionFeatures = () =>
  import('./lazy-motion-features').then((res) => res.default);

type AppProviderProps = GlobalProviderProps;

const swrConfig = {
  revalidateIfStale: false,
  revalidateOnMount: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
};

export const AppProvider: React.FC<AppProviderProps> = ({
  settings,
  global,
  footer,
  allMenuItems,
  featureToggle,
  children,
  siteLocale,
}) => {
  const active = [settings?.hostname, settings?.language?.urlPrefix]
    .filter(Boolean)
    .join('/');

  let currentLocale;

  if (siteLocale) {
    currentLocale = `${siteLocale.parttrapLanguage}-${siteLocale.parttrapMarket}`;
  }

  return (
    <SWRProvider config={swrConfig}>
      <LazyMotion features={lazyMotionFeatures}>
        <CartProvider settings={settings}>
          <FavoritesProvider settings={settings}>
            <GlobalProvider
              global={global}
              footer={footer}
              allMenuItems={allMenuItems}
              settings={settings}
              featureToggle={featureToggle}
              siteLocale={siteLocale}
            >
              <MarketPickerProvider
                label={global?.changeLanguage ?? ''}
                markets={global?.languages || []}
                active={active}
                currentLocale={currentLocale}
              >
                {children}
              </MarketPickerProvider>
            </GlobalProvider>
          </FavoritesProvider>
        </CartProvider>
      </LazyMotion>
    </SWRProvider>
  );
};
